import React from "react";
import { Alert as AntAlert } from "antd";

const FailureAlert = ({ message }) => <AntAlert type="error" message={message} />;
const SuccessAlert = ({ message }) => <AntAlert type="success" message={message} />;

const Alert = ({ style, messages = {} }) => {
  const { failure, success } = messages;

  return (
    <div style={style}>
      {failure && failure.length > 0 && <FailureAlert message={failure[0]} />}
      {success && success.length > 0 && <SuccessAlert message={success[0]} />}
    </div>
  );
};

export default Alert;
