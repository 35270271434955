import React from "react";
import ReactDOM from "react-dom";
import { AppLayout } from "../components/layout";
import Login from "../components/auth/login.jsx";

ReactDOM.render(
  <AppLayout viewModel={viewModel.appLayout} viewContext={viewModel.context}>
    <Login viewModel={viewModel.login} />
  </AppLayout>,
  document.getElementById("root")
);
