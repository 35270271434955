import React from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import Alert from "../alert.jsx";

const Login = ({ viewModel, viewContext }) => {
  const { messages } = viewModel;
  const { adminLocale, csrfToken } = viewContext;

  const intl = useIntl();

  return (
    <div style={{ width: "450px", margin: "auto", padding: "15px" }}>
      <h2>{intl.formatMessage({ id: "auth.login.title", defaultMessage: "Log in", description: "Title for the log in page" })}</h2>
      <Alert style={{ marginBottom: "10px" }} messages={messages} />
      <form method="post" action="/login" autoComplete="off">
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "auth.login.emailValidation",
                defaultMessage: "Please fill in your email",
                description: "Validation message in case the user forgot to fill in his/her email address"
              })
            }
          ]}
        >
          <Input
            name="username"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={intl.formatMessage({
              id: "auth.login.emailPlaceholder",
              defaultMessage: "Email",
              description: "Placeholder for the login email field"
            })}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "auth.login.passwordValidation",
                defaultMessage: "Please fill in your password",
                description: "Validation message in case the user forgot to fill in his/her password"
              })
            }
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            name="password"
            placeholder={intl.formatMessage({
              id: "auth.login.passwordPlaceholder",
              defaultMessage: "Password",
              description: "Placeholder for the login password field"
            })}
          />
        </Form.Item>
        <input type="hidden" value={csrfToken} name="_csrf" />
        <input type="hidden" value={adminLocale} name="cookieLanguage" />
        <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: "100%", marginBottom: "5px" }}>
          {intl.formatMessage({
            id: "auth.login.submitButton",
            defaultMessage: "Log in",
            description: "Button text for the login form submit"
          })}
        </Button>

        <a className="login-form-forgot" href="/forgot">
          {intl.formatMessage({
            id: "auth.login.forgotPassword",
            defaultMessage: "Forgot password",
            description: "Text for the forgot password link"
          })}
        </a>
      </form>
    </div>
  );
};

export default Login;
